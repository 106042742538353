import { motion } from 'framer-motion';
import Constellation from './components/Constellation';
import Feature from './components/Feature';

function App() {
  return (
    <div className="min-h-screen text-white">
      <Constellation />
      
      {/* Header */}
      <header className="container mx-auto px-4 py-6">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.7 }}
          transition={{ duration: 5 }}
          className="flex justify-between items-center"
        >
          <h1 className="text-2xl font-bold text-orange-500">Nulang Solutions</h1>
          <nav>
            <ul className="flex space-x-6">
              <li><a href="#features" className="hover:text-orange-400 transition-colors">Features</a></li>
              <li><a href="#about" className="hover:text-orange-400 transition-colors">About</a></li>
              <li><a href="#contact" className="hover:text-orange-400 transition-colors">Contact</a></li>
            </ul>
          </nav>
        </motion.div>
      </header>

      {/* Hero Section */}
      <section className="container mx-auto px-4 py-20 text-center">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 0.7, y: 0 }}
          transition={{ delay: 0.2, duration: 5 }}
          className="text-5xl font-bold mb-6 text-white-FFF"
        >
          High-Performance Data Solutions
        </motion.h2>
        <motion.p 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 0.7, y: 0 }}
          transition={{ delay: 0.4, duration: 5 }}
          className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto"
        >
          Empowering developers with blazing-fast native libraries for loseless compression, JSON processing and SQL Databases
        </motion.p>
      </section>

      {/* Features Section */}
      <section id="features" className="container mx-auto px-4 py-20">
        <h2 className="text-3xl font-bold text-center mb-12 text-orange-400">Our Solutions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <Feature 
            icon="🚀"
            title="TurboSqueeze - High-Speed Compression"
            description="State-of-the-art lossless compression algorithms optimized for maximum performance"
          />
          <Feature 
            icon="⚡"
            title="TurboJSON - JSON Processing"
            description="Lightning-fast JSON parsing and formatting with minimal memory footprint"
          />
          <Feature 
            icon="📦"
            title="DragonFruit SQL - Revolutionary Database"
            description="Built with the fastest code for optimal operation cost efficiency across all platforms"
          />
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="container mx-auto px-4 py-20">
        <div className="bg-white/5 backdrop-blur-lg rounded-xl p-8 max-w-3xl mx-auto">
          <h2 className="text-3xl font-bold mb-6 text-orange-400">About Us</h2>
          <p className="text-gray-300 mb-4">
            At Nulang Solutions, we're passionate about creating high-performance tools that make your accountant and investors smile when they look at the bills. Our native libraries are built with a focus on speed, efficiency, and reliability.
          </p>
          <p className="text-gray-300">
            We understand the challenges of handling large datasets and complex JSON structures, which is why we've developed optimized solutions that work seamlessly across different platforms.
          </p>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="container mx-auto px-4 py-20">
        <div className="text-center">
          <h2 className="text-3xl font-bold mb-6 text-orange-400">Get in Touch</h2>
          <p className="text-gray-300 mb-8">
            Interested in our solutions? We'd love to hear from you.
          </p>
          <a 
            href="mailto:contact@nulangsolutions.com"
            className="inline-block bg-orange-500 hover:bg-orange-600 text-white px-8 py-3 rounded-lg transition-colors"
          >
            Contact Us
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer className="container mx-auto px-4 py-8 text-center text-gray-400">
        <p>© 2024 Nulang Solutions. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;